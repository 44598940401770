import {
  Container,
  Nav,
  Navbar,
  Form,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import { useRef, useState } from "react";

import RegionChart from "./components/RegionChart";

const Config = {
  sign_window: {
    label: "Sign Window",
    help: "Used to soft the sign series.",
    default: 5,
  },
  ascend_window: {
    label: "Ascend Window",
    help: "Used to detect ascend periods after window has passed.",
    default: 5,
  },
  descent_window: {
    label: "Descend Window",
    help: "Used to detect descend periods after window has passed.",
    default: 5,
  },
  plateau_window: {
    label: "Plateau Window",
    help: "Used to detect plateau periods after window has passed.",
    default: 5,
  },
  period_min_days: {
    label: "Period Min. Days",
    help: "Minimum number of days required to be a valid wave period.",
    default: 14,
  },
  restart_min_days: {
    label: "Restart Min. Days",
    help: "Minimum number of days required to be have a period restart.",
    default: 7,
  },
};

const SmoothOptions = {
  simple_moving_average: "Simple Moving Average",
  exponential_moving_average: "Exponential Moving Average",
  savgol_filter: "Savgol Filter",
};

const SmoothParamsByFunction = {
  simple_moving_average: {
    window: {
      label: "Size of the moving window",
      default: 7,
      help: "This is the number of observations used for calculating the statistic. Each window will be a fixed size.",
    },
    min_periods: {
      label: "Min. Periods",
      default: 1,
      help: "Minimum number of observations in window required to have a value.",
    },
    closed: {
      label: "Closed on...",
      default: "right",
      help: "Options: ‘right’, ‘left’, ‘both’ or ‘neither’.",
    },
  },
  cumulative_moving_average: {
    min_periods: {
      label: "Min. Periods",
      default: 1,
      help: "Minimum number of observations in window required to have a value.",
    },
  },
  exponential_moving_average: {
    alpha: {
      label: "Smoothing Factor",
      default: 0.1,
      help: "0 < α <= 1",
    },
    min_periods: {
      label: "Min. Periods",
      default: 1,
      help: "Minimum number of observations in window required to have a value.",
    },
  },
  savgol_filter: {
    window_length: {
      label: "Window Length",
      default: 7,
      help: "Must be a positive odd integer.",
    },
    polyorder: {
      label: "Polyorder",
      default: 2,
      help: "The order of the polynomial used to fit the samples.",
    },
    mode: {
      label: "Mode",
      default: "interp",
      help: "Must be ‘mirror’, ‘constant’, ‘nearest’, ‘wrap’ or ‘interp’.",
    },
    cval: {
      label: "Constant",
      help: "Value to fill past the edges of the input if mode is ‘constant’.",
      default: 0.0,
    },
  },
};

function App() {
  const regionInput = useRef();
  const [region, setRegion] = useState();

  const [config, setConfig] = useState({});

  const [effectiveConfig, setEffectiveConfig] = useState(config);

  return (
    <main>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="#home">AutoCOVS</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="#link">About</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Container>
        <Row>
          <Col md={3}>
            <Row className="mt-4">
              <Col md={12}>
                <h3>Configuration</h3>
                <Form
                  onSubmit={(event) => {
                    event.preventDefault();
                    setRegion(regionInput.current?.value);
                    setEffectiveConfig(config);
                  }}
                >
                  <Form.Group className="mb-3" controlId="region">
                    <Form.Label>Region </Form.Label>
                    <Form.Control
                      ref={regionInput}
                      type="text"
                      placeholder="Enter region"
                    />
                    <Form.Text className="text-muted">
                      For example: BRA/Rio Grande do Sul
                    </Form.Text>
                  </Form.Group>

                  {Object.entries(Config).map(([fieldId, fieldConfig]) => (
                    <Form.Group
                      key={fieldId}
                      className="mb-3"
                      controlId={fieldId}
                    >
                      <Form.Label>{fieldConfig.label}</Form.Label>
                      <Form.Control
                        value={config[fieldId]}
                        onChange={({ target }) =>
                          setConfig({ ...config, [fieldId]: target.value })
                        }
                        defaultValue={fieldConfig.default}
                        placeholder={fieldConfig.default}
                      />
                      <Form.Text className="text-muted">
                        {fieldConfig.help}
                      </Form.Text>
                    </Form.Group>
                  ))}

                  <Form.Group className="mb-3" controlId="smooth_fn">
                    <Form.Label>Smooth Function</Form.Label>
                    <Form.Select
                      defaultValue="simple_moving_average"
                      value={config.smooth_fn}
                      onChange={({ target }) =>
                        setConfig({ ...config, smooth_fn: target.value })
                      }
                    >
                      {Object.entries(SmoothOptions).map(([value, label]) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                  {Object.entries(
                    SmoothParamsByFunction[
                      config.smooth_fn ?? "simple_moving_average"
                    ]
                  ).map(([paramId, paramConfig]) => (
                    <Form.Group
                      key={paramId}
                      className="mb-3"
                      controlId={paramId}
                    >
                      <Form.Label>{paramConfig.label}</Form.Label>
                      <Form.Control
                        value={config.smooth_params?.[paramId]}
                        onChange={({ target }) =>
                          setConfig({
                            ...config,
                            smooth_params: {
                              ...config.smooth_params,
                              [paramId]: target.value,
                            },
                          })
                        }
                        defaultValue={paramConfig.default}
                        placeholder={paramConfig.default}
                      />
                      <Form.Text className="text-muted">
                        {paramConfig.help}
                      </Form.Text>
                    </Form.Group>
                  ))}

                  <Button variant="primary" type="submit">
                    Save
                  </Button>
                </Form>
              </Col>
            </Row>
          </Col>
          <Col md={9}>
            {region && (
              <Row className="mt-4">
                {/* <Col md={12}>
            <h5>Confirmed (Cumulative)</h5>
            <RegionChart region={region} attribute={'confirmed'} title="Confirmed (Cumulative)" />
          </Col>
          <Col md={12}>
            <h5>Confirmed (Daily)</h5>
            <RegionChart region={region} attribute={'confirmed_daily'} title="Confirmed (Daily)" />
          </Col> */}
                {/* <Col md={12}>
            <h5>Confirmed (7d)</h5>
            <RegionChart region={region} attribute={'confirmed_daily_7d'} title="Confirmed (7d)" />
          </Col>
          <Col md={12}>
            <h5>Confirmed (14d)</h5>
            <RegionChart region={region} attribute={'confirmed_daily_14d'} title="Confirmed (14d)" />
          </Col> */}
                <Col md={12}>
                  <h5>{region} - Confirmed (21d)</h5>
                  <RegionChart
                    region={region}
                    attribute={"confirmed_daily_21d"}
                    title="Confirmed"
                    config={effectiveConfig}
                  />
                </Col>
                {/* <Col md={12}>
            <h5>Deaths (Cumulative)</h5>
            <RegionChart region={region} attribute={'deaths'} title="Deaths (Cumulative)" />
          </Col>
          <Col md={12}>
            <h5>Deaths (Daily)</h5>
            <RegionChart region={region} attribute={'deaths_daily'} title="Deaths (Daily)" />
          </Col> */}
                {/* <Col md={12}>
            <h5>Deaths (7d)</h5>
            <RegionChart region={region} attribute={'deaths_daily_7d'} title="Deaths (7d)" />
          </Col>
          <Col md={12}>
            <h5>Deaths (14d)</h5>
            <RegionChart region={region} attribute={'deaths_daily_14d'} title="Deaths (14d)" />
          </Col> */}
                <Col md={12}>
                  <h5>{region} - Deaths (21d)</h5>
                  <RegionChart
                    region={region}
                    attribute={"deaths_daily_21d"}
                    title="Deaths (21d)"
                    config={effectiveConfig}
                  />
                </Col>
                {/* <Col md={12}>
            <h5>Tests (Cumulative)</h5>
            <RegionChart region={region} attribute={'tests'} title="Tests (Cumulative)" />
          </Col>
          <Col md={12}>
            <h5>Tests (Daily)</h5>
            <RegionChart region={region} attribute={'tests_daily'} title="Tests (Daily)" />
          </Col>
          <Col md={12}>
            <h5>Tests (7d)</h5>
            <RegionChart region={region} attribute={'tests_daily_7d'} title="Tests (7d)" />
          </Col>
          <Col md={12}>
            <h5>Tests (14d)</h5>
            <RegionChart region={region} attribute={'tests_daily_14d'} title="Tests (14d)" />
          </Col>
          <Col md={12}>
            <h5>Vaccines (Cumulative)</h5>
            <RegionChart region={region} attribute={'vaccines'} title="Vaccines (Cumulative)" />
          </Col>
          <Col md={12}>
            <h5>Vaccines (Daily)</h5>
            <RegionChart region={region} attribute={'vaccines_daily'} title="Vaccines (Daily)" />
          </Col>
          <Col md={12}>
            <h5>Vaccines (7d)</h5>
            <RegionChart region={region} attribute={'vaccines_daily_7d'} title="Vaccines (7d)" />
          </Col>
          <Col md={12}>
            <h5>Vaccines (14d)</h5>
            <RegionChart region={region} attribute={'vaccines_daily_14d'} title="Vaccines (14d)" />
          </Col> */}
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </main>
  );
}

export default App;
