import { Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import styled from "styled-components";
import { useMemo } from "react";

import { API_URL } from "../constants";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import more from "highcharts/highcharts-more";
import xrange from "highcharts/modules/xrange";
import { transparentize } from "polished";

more(Highcharts);
xrange(Highcharts);

const Colors = [
  "#ffffb2",
  "#fed976",
  "#feb24c",
  "#fd8d3c",
  "#f03b20",
  "#bd0026",
];

const PeriodColors = [
  "#8dd3c7",
  "#ffffb3",
  "#bebada",
  "#fb8072",
  "#80b1d3",
  "#fdb462",
  "#b3de69",
  "#fccde5",
  "#d9d9d9",
  "#bc80bd",
  "#ccebc5",
  "#ffed6f",
];

const Measures = {
  school_closing: "School Closing",
  workplace_closing: "Workplace Closing",
  cancel_events: "Cancel Events",
  gatherings_restrictions: "Gatherings Restrictions",
  transport_closing: "Transport Closing",
  stay_home_restrictions: "Stay Home Restrictions",
  internal_movement_restrictions: "Internal Movement Restrictions",
  international_movement_restrictions: "International Movement Restrictions",
  information_campaigns: "Information Campaigns",
  testing_policy: "Testing Policy",
  contact_tracing: "Contact Tracing",
};

async function fetchRecords(region) {
  const response = await fetch(`${API_URL}/records/${region}`);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
}
async function fetchFeaturedPeriods(region, options) {
  const qa = new URLSearchParams();

  Object.entries(options).forEach(([key, value]) => {
    qa.append(key, value);
  });

  const response = await fetch(
    `${API_URL}/featured_periods/${region}?${qa.toString()}`
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
}

function RegionChart({ region, attribute, title, config = {} }) {
  const { data: records, isLoading: isLoadingRecords } = useQuery(
    region && ["records", region],
    () => fetchRecords(region)
  );

  const { data: featuredPeriods, isLoading: isLoadingFeaturedPeriods } =
    useQuery(region && ["featured_periods", region, attribute, config], () =>
      fetchFeaturedPeriods(region, {
        ...config,
        target_column: attribute,
      })
    );

  const isLoading = isLoadingRecords || isLoadingFeaturedPeriods;

  const timelineOptions = useMemo(() => {
    const categories = Object.keys(Measures);

    const startDate = {};

    const data = [];

    records?.forEach((record, index, arr) => {
      const prevRecord = index > 0 ? arr[index - 1] : null;
      const isLast = index === arr.length - 1;

      categories.forEach((attr, y) => {
        if (prevRecord === null) {
          startDate[attr] = record.date;
          return;
        }

        if (record[attr] !== prevRecord[attr]) {
          const endDate = prevRecord.date;
          data.push({
            y,
            x: startDate[attr],
            x2: endDate,
            color: Colors[prevRecord[attr]],
          });
          startDate[attr] = record.date;
        }

        if (isLast) {
          data.push({
            y,
            x: startDate[attr],
            x2: record.date,
            color: Colors[record[attr]],
          });
        }
      });
    });

    return {
      chart: {
        type: "xrange",
        width: 920,
      },
      title: {
        text: null,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        type: "datetime",
      },
      yAxis: {
        title: {
          text: "",
        },
        categories: categories.map((k) => Measures[k]),
        reversed: true,
      },
      legend: {
        enabled: false,
      },
      series: [
        {
          name: region,
          borderColor: "gray",
          pointWidth: 20,
          data: data,
          dataLabels: {
            enabled: true,
          },
        },
      ],
    };
  }, [records, region]);

  const chartOptions = useMemo(() => {
    return {
      chart: {
        zoomType: "x",
        height: 300,
        width: 920,
        spacingLeft: 150,
      },
      title: {
        text: null,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        type: "datetime",
        plotBands: featuredPeriods?.featured_periods.map(
          ({ start, end, id }, index) => {
            return {
              color: transparentize(
                0.5,
                PeriodColors[index % PeriodColors.length]
              ), // Color value
              from: start, // Start of the plot band
              to: end, // End of the plot band
            };
          }
        ),
      },
      yAxis: {
        title: {
          text: title,
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        area: {
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: [
              [0, Highcharts.getOptions().colors[0]],
              [
                1,
                Highcharts.color(Highcharts.getOptions().colors[0])
                  .setOpacity(0)
                  .get("rgba"),
              ],
            ],
          },
          marker: {
            radius: 2,
          },
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1,
            },
          },
          threshold: null,
        },
      },
      series: [
        {
          type: "area",
          name: title,
          data: records?.map((x) => [x.date, x[attribute]]),
        },
        {
          type: "line",
          color: "red",
          name: `${title} (Smoothed)`,
          data: featuredPeriods?.records.map((x) => [
            x.date,
            Math.max(0, x[`${attribute}_smoothed`]),
          ]),
        },
      ],
    };
  }, [attribute, featuredPeriods, records, title]);

  if (isLoading) {
    return (
      <Container>
        <Spinner animation="border" />
      </Container>
    );
  }

  return (
    <div>
      <Container>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </Container>

      <Container>
        <HighchartsReact highcharts={Highcharts} options={timelineOptions} />
      </Container>
    </div>
  );
}

export default RegionChart;

const Container = styled.div``;
